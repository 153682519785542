import { ACCESS_TOKEN_SALES_KEY_NAME } from "@nju/scope";
import { decodeToken } from "@nju/data-access-portal-wwt";

export function getUserName() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_SALES_KEY_NAME);

  const decodedToken = decodeToken(accessToken);

  return decodedToken?.name;
}
