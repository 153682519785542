import { VisuallyHidden } from "@react-aria/visually-hidden";
import Link from "next/link";
import { Icon, IconStyleProps } from "@nju/icons";

interface ILogoProps {
  tone: IconStyleProps["tone"];
  href: string;
}

export function Logo({ href, tone }: ILogoProps) {
  return (
    <Link data-cy="logo" href={href} title="Strona główna nju.pl">
      <Icon className="w-20 h-7" name="nju" tone={tone} />
      <VisuallyHidden>Strona główna nju.pl</VisuallyHidden>
    </Link>
  );
}
