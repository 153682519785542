import * as React from "react";
// @TODO move from WWT

export type IAddressInstallationStandard =
  | "stdAir"
  | "nonStdAir"
  | "stdGround"
  | "nonStdGround"
  | "notDefined"
  | null;

interface IAddress {
  street?: string;
  streetNumber: string;
  municipality: string;
  apartmentNumber?: string;
  owner: string;
  isSfh: boolean;
  installationStandard: IAddressInstallationStandard;
}

export interface IDecodedToken {
  address: IAddress;
  limited: boolean;
}

export function useDecodedToken(
  token: string | null | undefined
): IDecodedToken | undefined {
  return React.useMemo(() => {
    return decodeToken(token);
  }, [token]);
}

export function decodeToken(token: string | null | undefined) {
  if (!token) {
    return;
  }

  try {
    return JSON.parse(
      Buffer.from(token.split(".")[1], "base64").toString("utf8")
    );
  } catch {
    return;
  }
}
