import * as React from "react";
import { useRouter } from "next/router";
import { cx, media } from "@nju/css";
import { useSellerLoginState } from "@nju/data-access-sales-seller";
import { BlockContent, useOverlay } from "@nju/ui";
import { Icon } from "@nju/icons";
import { Logo } from "./Logo";
import { getUserName } from "./utils";
import { UserName } from "./UserName";
import { salesRoutes } from "@nju/routes";
import { NavigationDrawer } from "./NavigationDrawer";
import { useMedia } from "use-media";

export function Header() {
  const drawer = useOverlay();
  const router = useRouter();
  const { isLoggedIn } = useSellerLoginState();
  const [userName, setUserName] = React.useState("");
  const isMobile = useMedia(media.onlyMobile);

  React.useEffect(() => {
    async function recheck() {
      setUserName(getUserName());
    }
    recheck();
    const id = setInterval(recheck, 5000);

    router.events.on("routeChangeComplete", recheck);
    return () => {
      router.events.off("routeChangeComplete", recheck);
      clearInterval(id);
    };
  }, [router.events]);

  return (
    <>
      {drawer.isOpened && (
        <NavigationDrawer onClose={drawer.close} userName={userName} />
      )}
      <div className="flex relative w-full overflow-hidden items-center min-h-[96px]">
        <BlockContent spacing={{ "@onlyMobile": "m", "@fromTablet": "l" }}>
          <div className="flex items-center">
            <div
              className={cx("flex only-mobile:flex-col from-tablet:flex-row", {
                "only-mobile:flex-initial": isLoggedIn,
                "only-mobile:flex-1": !isLoggedIn,
              })}
            >
              <Logo
                href={
                  isLoggedIn ? salesRoutes.panel.get() : salesRoutes.index.get()
                }
                tone="info"
              />
            </div>
            <div className="flex ml-auto">
              {isLoggedIn && userName && (
                <div className="flex">
                  {!isMobile && (
                    <UserName
                      iconTone="secondary"
                      textTone="secondary"
                      userName={userName}
                    />
                  )}
                  <div
                    className="flex ml-auto right-3 self-start cursor-pointer"
                    onClick={drawer.open}
                  >
                    <Icon
                      data-cy="agent-menu-button"
                      height={24}
                      name="bars-bold"
                      tone="secondary"
                      width={21}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </BlockContent>
      </div>
    </>
  );
}
