import { css } from "@nju/css";
import * as React from "react";

export const HeaderBackgroundWrapper = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <div className={wrapperClass()}>{children}</div>;
};

const wrapperClass = css({
  background:
    "linear-gradient(50deg, $cerulean 0%, $cerulean 60%, $brightCerulean 60%, $brightCerulean 100%)",
});
