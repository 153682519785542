import { salesRoutes } from "@nju/routes";
import { BlockText, Button, ButtonsWrapper, Separator } from "@nju/ui";
import { useRouter } from "next/router";
import * as React from "react";
import { useMachine } from "@xstate/react";
import { ErrorMessage } from "@nju/form";
import { machine } from "./logout.machine";
import { generalErrorMessage } from "@nju/utils";
import { IOfferMode } from "@nju/data-access-portal-offers";

interface ILogoutModalProps {
  onClose: () => void;
  onLogout: () => void;
  mode?: IOfferMode;
}

export function LogoutModal({ onClose, onLogout }: ILogoutModalProps) {
  const router = useRouter();

  const onSuccess = React.useCallback(() => {
    router.push(salesRoutes.index.get());
    onLogout();
  }, [onLogout, router]);

  const [current, send] = useMachine(machine, {
    actions: {
      onSuccess,
    },
  });

  return (
    <>
      <Separator size="xxs" tone="invisible" />
      <BlockText
        size={{
          "@onlyMobile": "s",
          "@fromTablet": "l",
        }}
      >
        <BlockText
          size={{
            "@onlyMobile": "s",
            "@fromTablet": "l",
          }}
          weight="bold"
        >
          Jesteś w trakcie sprzedaży?
        </BlockText>
        Jeśli się teraz wylogujesz, to w systemie pozostanie jedynie konto
        klienta. Przy ponownej próbie zakupu możesz przywrócić dane klienta
        (m.in. imię i nazwisko), wpisując jego adres e-mail.
        <Separator size="s" tone="invisible" />
        <BlockText
          size={{
            "@onlyMobile": "s",
            "@fromTablet": "l",
          }}
          weight="bold"
        >
          Nie jesteś w trakcie sprzedaży?
        </BlockText>
        Wylogowanie się nie spowoduje utraty żadnych danych.
      </BlockText>
      <Separator size="xxs" tone="invisible" />
      <ButtonsWrapper withSeparator={false}>
        <Button
          onPress={onClose}
          size={{ "@onlyMobile": "s" }}
          variant="secondary"
        >
          anuluj
        </Button>
        <Button
          isLoading={current.matches("logout")}
          onPress={() => {
            send("LOGOUT");
          }}
          size={{ "@onlyMobile": "s" }}
        >
          tak, wyloguj się
        </Button>
        {current.matches("error") && (
          <ErrorMessage message={generalErrorMessage} />
        )}
      </ButtonsWrapper>
    </>
  );
}
