import * as React from "react";
import { css } from "@nju/css";
import { Icon } from "@nju/icons";
import {
  useOverlay as useAriaOverlay,
  usePreventScroll,
  useModal,
  OverlayContainer,
  OverlayProps,
} from "@react-aria/overlays";
import { FocusScope } from "@react-aria/focus";
import { useDialog } from "@react-aria/dialog";

type IDrawerProps = React.PropsWithChildren<
  OverlayProps & {
    role?: "dialog" | "alertdialog";
    title?: string;
    dismissVariant?: "close" | "back" | "none";
    direction?: "right" | "top";
  }
>;

function DrawerDialog(props: IDrawerProps) {
  const {
    children,
    onClose,
    dismissVariant = "back",
    direction = "right",
  } = props;

  const ref = React.useRef<HTMLDivElement | null>(null);
  const { overlayProps } = useAriaOverlay(
    {
      isDismissable: true,
      isOpen: true,
      isKeyboardDismissDisabled: false,
      ...props,
    },
    ref
  );

  usePreventScroll();

  const { modalProps } = useModal();
  const { dialogProps } = useDialog(props, ref);

  return (
    <div className={styles.dialog()}>
      <FocusScope autoFocus={true} contain={true} restoreFocus={true}>
        <div
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          className={styles.modalWrapper({ direction })}
          data-cy="offer-details-window"
          ref={ref}
        >
          <div>
            <div className="flex">
              <div onClick={onClose}>
                {dismissVariant === "close" && (
                  <span className={styles.modalCloseButton()}>
                    <Icon height={24} name="times" width={24} />
                  </span>
                )}
                {dismissVariant === "back" && (
                  <span className="flex gap-3 cursor-pointer items-center text-size-20">
                    <Icon height={24} name="arrow-left" width={21} /> wróć
                  </span>
                )}
              </div>
            </div>
            {children}
          </div>
        </div>
      </FocusScope>
    </div>
  );
}

export function Drawer(props: IDrawerProps & { title?: string }) {
  return (
    <OverlayContainer>
      <DrawerDialog {...props} />
    </OverlayContainer>
  );
}

const styles = {
  dialog: css({
    position: "fixed",
    zIndex: "$3",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  modalOverlay: css({
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "1040",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#000",
    opacity: ".7",
  }),
  modalWrapper: css({
    position: "fixed",
    top: "0",
    zIndex: "1050",
    width: "100%",
    overflowX: "hidden",
    outline: "0",
    backgroundColor: "$white",
    padding: "30px",
    variants: {
      direction: {
        top: {
          "@fromTablet": {
            height: "380px",
            padding: "30px",
          },
          "@onlyMobile": {
            height: "100vh",
            padding: "15px",
          },
        },
        right: {
          right: "0",
          height: "100vh",
          maxWidth: "620px",
          transition: "all 8000ms ease-in-out",
          padding: "30px",
        },
      },
    },
  }),
  modalArrow: css({
    display: "flex",
    gap: "15px",
    fontSize: "20px",
    cursor: "pointer",
  }),
  modalCloseButton: css({
    position: "absolute",
    right: 20,
    top: 20,
  }),
};
