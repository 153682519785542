import * as React from "react";
import {
  Drawer,
  A,
  Text,
  BlockContent,
  useOverlay,
  Modal,
  Button,
  BlockText,
  Separator,
} from "@nju/ui";
import { Logo } from "./Logo";
import { salesRoutes } from "@nju/routes";
import { LogoutModal } from "./LogoutModal";
import { useRouter } from "next/router";
import { useOfferMode } from "@nju/data-access-portal-offers";
import { UserName } from "./UserName";
import { Icon } from "@nju/icons";
import Link from "next/link";
import { useMedia } from "use-media";
import { media } from "@nju/css";

interface INavigationDrawerProps {
  onClose: () => void;
  userName: string;
}

export function NavigationDrawer({
  onClose,
  userName,
}: INavigationDrawerProps) {
  const modal = useOverlay();
  const mode = useOfferMode();
  const router = useRouter();
  const isMobile = useMedia(media.onlyMobile);

  const onLogout = React.useCallback(() => {
    router.push(salesRoutes.index.get());
    modal.close();
  }, [modal, router]);

  return (
    <Drawer direction="top" dismissVariant="none" onClose={onClose}>
      <div className="flex relative w-full overflow-hidden items-center">
        <BlockContent spacing={{ "@onlyMobile": "s" }} spacingRow="none">
          <div className="flex only-mobile:flex-col only-mobile:gap-5 from-tablet:justify-between from-tablet:pb-6">
            <Logo href={salesRoutes.panel.get()} tone="neutral" />
            <div className="flex from-tablet:ml-auto">
              <div className="flex">
                {!isMobile && (
                  <UserName
                    iconTone="accent"
                    textTone="accent"
                    userName={userName}
                  />
                )}
                <div
                  className="flex ml-auto right-3 self-start pt-2 cursor-pointer only-mobile:absolute only-mobile:top-0 only-mobile:right-1"
                  onClick={onClose}
                >
                  <Icon
                    data-cy="close-agent-menu-button"
                    height={24}
                    name="times"
                    tone="neutral"
                    width={24}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col from-tablet:flex-row from-tablet:pt-5">
            <div className="flex flex-col justify-start gap-4 from-tablet:gap-5 from-tablet:border-r-1 from-tablet:border-geyser from-tablet:w-full from-tablet:max-w-[536px]">
              <BlockText size="s">
                Masz pytania? Przeszukaj bazę wiedzy:
              </BlockText>
              <div className="flex">
                <Link href={salesRoutes.instrukcjeOferty.get()} passHref={true}>
                  <A display="inline" size="inline" variant="simple">
                    <div className="flex items-center">
                      <Icon
                        height={24}
                        name="book-open"
                        tone="neutral"
                        width={24}
                      />
                      <div className="ml-2">
                        <Text tone="neutral" weight="bold">
                          instrukcje i oferty
                        </Text>
                      </div>
                    </div>
                  </A>
                </Link>
              </div>
              <div className="flex">
                <Link
                  href="https://esklep.online.orange.pl/view/omnimain"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="flex items-center">
                    <Icon height={24} name="book" tone="neutral" width={24} />
                    <div className="ml-2">
                      <Text tone="neutral" weight="bold">
                        omnibook sieć agencyjna
                      </Text>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="flex">
                <Link
                  href="https://esklep.online.corpnet.pl/view/omnimain"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="flex items-center">
                    <Icon height={24} name="book" tone="neutral" width={24} />
                    <div className="ml-2">
                      <Text tone="neutral" weight="bold">
                        omnibook sieć korporacyjna
                      </Text>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="flex">
                <Link
                  href={salesRoutes.pomoc.get()}
                  passHref={true}
                  target="_blank"
                >
                  <A size="inline" variant="neutral">
                    <div className="flex items-center">
                      <Icon
                        height={24}
                        name="question-circle"
                        tone="neutral"
                        width={24}
                      />
                      <div className="ml-2">
                        <Text tone="neutral" weight="bold">
                          portal pomocy dla klientów
                        </Text>
                      </div>
                    </div>
                  </A>
                </Link>
              </div>
            </div>
            {isMobile && (
              <Separator
                size={{ "@onlyMobile": "l", "@fromTablet": "none" }}
                tone={{ "@onlyMobile": "soft", "@fromTablet": "invisible" }}
              />
            )}
            <div className="flex flex-col justify-start gap-4 from-tablet:gap-5 from-tablet:pl-5 from-tablet:w-full from-tablet:max-w-[536px]">
              <BlockText size="s">
                Nie udało Ci się znaleźć odpowiedzi na swoje pytanie? Zadzwoń:
              </BlockText>
              <div className="flex">
                {isMobile && (
                  <Link
                    href="tel:+48800112112"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div className="flex items-center">
                      <Icon
                        height={24}
                        name="phone-alt"
                        tone="neutral"
                        width={24}
                      />
                      <div className="ml-2">
                        <Text tone="neutral">
                          <span className="font-bold pr-2">800 112 112</span>{" "}
                          (wew. 7)
                        </Text>
                      </div>
                    </div>
                  </Link>
                )}
                {!isMobile && (
                  <div className="flex items-center">
                    <Icon
                      height={24}
                      name="phone-alt"
                      tone="neutral"
                      width={24}
                    />
                    <div className="ml-2">
                      <Text tone="neutral">
                        <span className="font-bold pr-2">800 112 112</span>{" "}
                        (wew. 7)
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {isMobile && (
                <>
                  <Separator size="l" />
                  <div className=" pb-3">
                    <UserName
                      iconTone="accent"
                      textTone="accent"
                      userName={userName}
                    />
                  </div>
                </>
              )}
              <Button
                display="inline"
                onPress={modal.open}
                size="inline"
                variant="simple"
              >
                <Icon
                  className="pb-[2px]"
                  height={24}
                  name="sign-out"
                  tone="neutral"
                  width={24}
                />
                <Text className="pl-2" size="m" tone="neutral" weight="bold">
                  wyloguj
                </Text>
              </Button>
            </div>
          </div>
        </BlockContent>
        {modal.isOpened && (
          <Modal
            onClose={modal.close}
            title="Czy na pewno chcesz się wylogować?"
          >
            <LogoutModal
              mode={mode}
              onClose={modal.close}
              onLogout={onLogout}
            />
          </Modal>
        )}
      </div>
    </Drawer>
  );
}
