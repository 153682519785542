/* eslint-disable unicorn/no-null */
import * as React from "react";
import {
  useOverlay as useAriaOverlay,
  usePreventScroll,
  useModal,
  OverlayContainer,
  OverlayProps,
} from "@react-aria/overlays";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { Text } from "../Text";
import { Icon } from "@nju/icons";

import { css } from "@nju/css";

type IModalProps = React.PropsWithChildren<
  OverlayProps & {
    role?: "dialog" | "alertdialog";
    title?: string;
    testId?: string;
    isOnCloseActive?: boolean;
  }
>;

function ModalDialog(props: IModalProps) {
  const { children, onClose, title, testId, isOnCloseActive = true } = props;

  const ref = React.useRef<HTMLDivElement | null>(null);
  const { overlayProps } = useAriaOverlay(
    {
      isDismissable: true,
      isOpen: true,
      isKeyboardDismissDisabled: false,
      ...props,
    },
    ref
  );

  usePreventScroll();

  const { modalProps } = useModal();
  const { dialogProps } = useDialog(props, ref);

  return (
    <div className={styles.dialog()}>
      <FocusScope autoFocus={true} contain={true} restoreFocus={true}>
        <div
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          className={styles.content()}
          data-cy={testId}
          ref={ref}
        >
          <div className={styles.wrapper()}>
            <div className={styles.header()}>
              {title && (
                <div className={styles.title()}>
                  <Text
                    size={{ "@onlyMobile": "xl", "@fromTablet": "keyM" }}
                    weight="bold"
                  >
                    {title}
                  </Text>
                </div>
              )}
              {isOnCloseActive && (
                <button
                  className={styles.close()}
                  onClick={onClose}
                  type="button"
                >
                  <Icon name="times" />
                  <VisuallyHidden>Zamknij warstwę</VisuallyHidden>
                </button>
              )}
            </div>
            {children}
          </div>
        </div>
      </FocusScope>
    </div>
  );
}

export function Modal(props: IModalProps & { title?: string }) {
  return (
    <OverlayContainer>
      <ModalDialog {...props} />
    </OverlayContainer>
  );
}

const styles = {
  header: css({
    display: "flex",
    justifyContent: "flex-end",
  }),
  title: css({
    mr: "auto",
    "@fromTablet": {
      pt: "$10",
      pb: "$20",
    },
  }),
  wrapper: css({
    display: "grid",
    width: "100%",
    "@fromTablet": {
      maxWidth: 600,
    },
    "@fromDesktop": {
      maxWidth: 800,
    },
  }),
  close: css({
    border: "none",
    background: "none",
    alignSelf: "flex-start",
    fontSize: 20,
    cursor: "pointer",
  }),
  dialog: css({
    position: "fixed",
    zIndex: 1000,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  content: css({
    "@onlyMobile": {
      width: "100%",
    },
    background: "$white",
    borderRadius: "$8",
    padding: "$20",
  }),
};
