import { Icon, IconStyleProps } from "@nju/icons";
import { Text, TextStyleProps } from "@nju/ui";

interface IUserNameProps {
  userName: string;
  textTone: TextStyleProps["tone"];
  iconTone: IconStyleProps["tone"];
}

export function UserName({ userName, textTone, iconTone }: IUserNameProps) {
  return (
    <div className="flex items-center pr-5">
      <Icon height={20} name="user-check" tone={iconTone} width={20} />
      <div className="ml-2">
        <Text tone={textTone}>{userName}</Text>
      </div>
    </div>
  );
}
