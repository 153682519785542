import { css } from "@nju/css";
import * as React from "react";

interface IButtonsWrapperProps {
  withSeparator?: boolean;
}
export function ButtonsWrapper({
  children,
  withSeparator = true,
}: React.PropsWithChildren<IButtonsWrapperProps>) {
  return <div className={buttons({ withSeparator })}>{children}</div>;
}

const buttons = css({
  pt: "$20",
  display: "flex",
  "@onlyMobile": {
    "* + *": {
      marginBottom: "$15",
    },
    flexDirection: "column-reverse",
  },
  "@fromTablet": {
    justifyContent: "space-between",
  },
  variants: {
    withSeparator: {
      true: {
        borderTop: "1px solid $geyser",
        mt: "$20",
        pt: "$30",
      },
    },
  },
});
