import { logout } from "@nju/data-access-sales-seller";
import { createMachine } from "xstate";

export const machine = createMachine(
  {
    schema: {
      events: {} as
        | { type: "LOGOUT" }
        | { type: "SUCCESS" }
        | { type: "ERROR" },
    },
    initial: "idle",
    states: {
      idle: {
        on: {
          LOGOUT: {
            target: "logout",
          },
        },
      },
      logout: {
        invoke: {
          src: "logout",
        },
        on: {
          SUCCESS: { target: "success", actions: "onSuccess" },
          ERROR: { target: "error" },
        },
      },
      error: {},
      success: {},
    },
  },
  {
    services: {
      logout: () => {
        return async (callback) => {
          const result = await logout();

          if (result.isOk) {
            callback({ type: "SUCCESS" });
          } else {
            callback({ type: "ERROR" });
          }
        };
      },
    },
  }
);
